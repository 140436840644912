// axios.js

import router from '@/router'
import { clearCookie } from '@/utils/cookies'
import axios, { HttpStatusCode } from 'axios'
import store from '@/store'

const httpCodeAuthErrorArray = [
  // `无登录凭证`
  2100,
  // `账号已在其他地方登录`
  2108,
  // `登录凭证无效`
  2101,
  // `登录凭证错误，请检查`
  2102,
  // `权限不足`
  2103,
  // `登录凭证已过期`
  2104,
  // `鉴权错误，请联系管理员`
  2105,
  // `鉴权错误，账号异常`
  2106,
  // 用户不存在
  13007
]
const httpCodeServerErrorArray = [
  // `Jackson `序列化异常`
  1001,
  // `Jackson `序列化初始化异常`
  1002,
  // `方法参数校验异常`
  1003,
  // `方法参数校验异常`
  1004,
  // `方法参数格式有误`
  1005,
  // `请求参数缺失`
  1006,
  // `不支持的Http请求方式`
  1007,
  // `未知异常,请联系管理员`
  1008,
  // `重复请求,请稍后再试`【分布式锁】
  1009,
  // `操作失败：加锁异常`【分布式锁】
  1010,
  // `操作失败：加锁异常`【分布式锁】
  1011
]

/** 服务器重启异常 */
export const HttpServerUpdateCode = 15011


// 请求拦截器
import CryptoJS from 'crypto-js'; // 假设您会使用 CryptoJS 进行加签
import forge from 'node-forge'
const publicKeyPEM = '-----BEGIN PUBLIC KEY-----\n' +
    'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBALqnZLMc4NIIsfdLoy0o7mimK8c8B6pR\n' +
    'gPVAxdrlJo/stqmK44AmA4QK0hFb7GVIlNkiWNeABVd6/RCvibGRnTUCAwEAAQ==\n' +
    '-----END PUBLIC KEY-----';
axios.defaults.baseURL = process.env.VUE_APP_BASE_API
const sortParam = (params) => {
  const newKey = Object.keys(params || {}).sort();
  let newObj = {};
  for (let i = 0; i < newKey.length; i++) {
      newObj[newKey[i]] = params[newKey[i]];
  }
  return newObj;
}

const getSignParam = (config) => {
  // 时间戳
  const timestamp = new Date().getTime().toString();
  // 请求param参数
  const body = sortParam(config.params);
  // 随机数
  const random = Math.random().toString(36).substring(2);
  return { timestamp, body, random };
}

const parseQueryParam = (config) => {
  if (config.url && config.url.includes('?')) {
      // 使用新的URL替换旧的URL
      const urlParts = config.url.split('?');
      const queryParamsString = urlParts[1];
      const queryParams = new URLSearchParams(queryParamsString);
      const queryParamsObject = Object.fromEntries(queryParams.entries());
      config.url = urlParts[0];
      config.params = { ...config.params, ...queryParamsObject };
  }
  return config;
}

axios.interceptors.request.use((config) => {
  config.headers['Authorization'] = window.localStorage.getItem('token')
  if (config.data) {
    // 删除空字符串的属性
    for (let key in config.data) {
      if (config.data[key] === '') {
        delete config.data[key]
      }
    }
  }
  // 将queryParam解析为对象params
  config = parseQueryParam(config);
  const signParam = getSignParam(config);
  const { timestamp, body, random } = signParam;
  if (config.method === 'post' || config.method === 'put') {
      signParam.body = sortParam({
          ...config.data,
          urlParams: body,
      });
  }
  signParam.body = JSON.stringify(signParam.body);
  // 签名
  const sign = CryptoJS.MD5(JSON.stringify(sortParam(signParam)));
  config = Object.assign(config, { headers: { ...config.headers, sign, timestamp, random } })
  return config;
});

axios.interceptors.response.use(res => {
  console.log('响应结果', res)
  // 如果结果加签了-才需要验签
  if(res.headers.timestamp && res.headers.sign) {
    // 得到真实的公钥信息
    const publicKey = forge.pki.publicKeyFromPem(publicKeyPEM);
    const signature = forge.util.decode64(res.headers.sign);
    // 验签
    const signParam = JSON.stringify({ result: res.data, timestamp: res.headers.timestamp })
    const md = forge.md.sha256.create();
    md.update(signParam, 'utf8');
    const verify = publicKey.verify(md.digest().bytes(), signature);
    console.log('验签结果', verify);
    // 当结果为true时，验签成功，否则验签失败
    if (!verify) {
      return { data: { code: 500, msg: '抱歉，当前操作遇到问题，请稍后再试。', message: '抱歉，当前操作遇到问题，请稍后再试。' } };
    }
  }
  if (res.data.message === '当前用户未加入企业') {
    res.data.code = 200
    res.data.result = null
  }
  // 处理服务不可用异常 维护中界面
  if (res.data.code === HttpServerUpdateCode) {
    store.commit('setSysUpdate', true)
  }
  if (httpCodeAuthErrorArray.includes(res.data.code)) {
    window.localStorage.clear()
    router.push('/login')
  }

  if (!res?.data?.data) {
    res.data.data = res.data.result
  }
  return res
})

export const loginOutApi = params => {
  return axios.get('/FOR_/login/loginOut')
}
// 登录
export const loginApi = params => {
  return axios.get('/FOR_/login/phonePswLogin?phone=' + params.phone + '&psw=' + params.psw + '&channel=' + ChannelEnum.GATHER)
}
// 验证码登录
export const codeLoginApi = params => {
  return axios.get('/FOR_/login/phoneCodeLogin?phone=' + params.phone + '&code=' + params.code + '&channel=' + ChannelEnum.GATHER)
}
// 获取用户信息
// export const  userInfoApi= params => {
//   return axios.get('/FOR_/login/userInfo')
// }
export const userInfoApi = params => {
  return axios.get('FOR_/user/userInfo')
}

// 获取登录
export const registerApi = params => {
  return axios.post('/FOR_/login/sign', { ...params, channel: ChannelEnum.GATHER })
}

// 产品询价
export const enquiryListApi = params => {
  return axios.get('/foreground/enquiryOffer/list?addressProvinceId=' + params.addressProvinceId + '&addressCityId=' + params.addressCityId + '&addressAreaId=' + params.addressAreaId + '&current=' + params.current + '&size=' + params.size + '&round=' + params.round + '&timeType=' + params.timeType + '&beginTime=' + params.beginTime + '&endTime=' + params.endTime + '&type=' + params.type + '&state=' + params.state)
}

// 产品询价报价
export const offerPriceApi = params => {
  return axios.post('/foreground/enquiryOffer/submit', params)
}
// 极速询价
export const addEnquiryApi = params => {
  return axios.post('/new/topSpeed/insert', params)
}

// 查看自己报价
export const myOfferPriceApi = params => {
  return axios.get('/foreground/enquiry/offerDetail?enquiryLineId=' + params)
}

// 供求企业列表
export const companyListApi = params => {
  return axios.get('/FOR_/userCompany/page?current=' + params.current + '&code=' + params.code + '&provinceCode=' + params.provinceCode + '&districtCode=' + params.districtCode + '&cityCode=' + params.cityCode + '&size=' + params.size + '&name=' + params.name + '&verificationMode=' + params.verificationMode)
}
export const companyInfoApi = params => {
  return axios.get('/FOR_/userCompany/info?id=' + params)
}
// 获取全部地址
export const addressApi = params => {
  return axios.get('/common/findAllArea')
}
// 询价热门推荐
export const enquiryHotListApi = params => {
  return axios.get('/foreground/enquiryOffer/hotList?current=' + params.current + '&size=' + params.size + '&type=' + params.type)
}
// 询价详情
export const enquiryDetailListApi = params => {
  return axios.get('/foreground/enquiryOffer/info?id=' + params.id + '&proId=' + params.proId)
}
// 首页全部分类数据
export const classificationsListApi = params => {
  return axios.get('/gat/industry/tree?type=' + params)
}

// 获取热门询价★
export const hotShopListApi = params => {
  return axios.get('/foreground/enquiryOffer/hotProList?current=' + params.current + '&size=' + params.size)
}
// 获取询价商品列表★
export const askPriceShopListApi = params => {
  return axios.get('/foreground/enquiryOffer/getProList?name=' + params.name + '&proName=' + params.proName + '&addressProvinceId=' + params.addressProvinceId + '&addressCityId=' + params.addressCityId + '&addressAreaId=' + params.addressAreaId + '&current=' + params.current + '&size=' + params.size + '&round=1' + '&timeType=' + params.timeType + '&beginTime=' + params.beginTime + '&endTime=' + params.endTime + '&type=' + params.type + '&industryId=' + params.industryId)
}
// 获取竞价采购列表★
export const biddingListApi = params => {
  return axios.get('/foreground/enquiryOffer/list?name=' + params.name + '&proName=' + params.proName + '&addressProvinceId=' + params.addressProvinceId + '&addressCityId=' + params.addressCityId + '&addressAreaId=' + params.addressAreaId + '&current=' + params.current + '&size=' + params.size + '&round=' + params.round + '&timeType=' + params.timeType + '&beginTime=' + params.beginTime + '&endTime=' + params.endTime + '&type=' + params.type + '&state=' + params.state)
}
// 获取拍卖单列表★
export const auctionListApi = params => {
  return axios.get('/foreground/auction/getProList?current=' + params.current + '&size=' + params.size + '&isHot=' + params.isHot)
}

// 拍卖单列表
export const auctionList2Api = params => {
  return axios.get('/foreground/auction/getProList?current=' + params.current + '&size=' + params.size + '&isHot=' + params.isHot + '&addressProvinceId=' + params.addressProvinceId + '&addressCityId=' + params.addressCityId + '&addressAreaId=' + params.addressAreaId + '&createTime=' + params.createTime + '&endTime=' + params.endTime + '&timeType=' + params.timeType)
}

// 拍卖单详情
export const auctionDetailApi = params => {
  return axios.get('/foreground/auction/proDetail?id=' + params.id + '&proId=' + params.proId)
}

// 拍卖单报价
export const offerAuctionPriceApi = params => {
  return axios.post('/foreground/auction/submit', params)
}

// 新闻分类tab列表
export const pressTypeListApi = params => {
  return axios.get('/gat/new/typeList')
}
// 新闻数据列表
export const pressListApi = params => {
  return axios.get('/gat/new/page?current=' + params.current + '&size=' + params.size + '&typeId=' + params.typeId + '&recSite=' + params.recSite + '&tag=' + params.tag)
}
export const pressInfoApi = params => {
  return axios.get('/gat/new/info?id=' + params)
}

// 校验手机号是否注册
export const checkPhoneApi = params => {
  return axios.get('/FOR_/login/checkPhone?phone=' + params.phone)
}

// 校验验证码是否正确
export const checkCodeApi = params => {
  return axios.post('/FOR_/login/changeCode?phone=' + params.phone + '&code=' + params.code)
}

// 修改密码
export const editPswApi = params => {
  return axios.post('/FOR_/login/changePsw?phone=' + params.phone + '&code=' + params.code + '&psw=' + params.psw)
}

// 发送验证码
export const sendCodeApi = params => {
  return axios.get('/FOR_/login/sendMessage?phone=' + params.phone + '&type=' + params.type)
}

// 询价竞价采购列表筛选统计
export const askPriceNumberApi = params => {
  return axios.get('/foreground/enquiry/btnStat?type=' + params)
}

// 供应端报价单筛选统计
export const sellAskPriceNumberApi = params => {
  return axios.get('/foreground/enquiry/offerStat?type=' + params)
}

// 获取所有省市区分类
export const getProvinceAllApi = params => {
  return axios.get('/common/findAllArea')
}

// 收货地址
export const addressListApi = params => {
  return axios.get('/MID_/companyAddress/page?current=' + params.current + '&size=' + params.size + '&provinceCode=' + params.provinceCode + '&cityCode=' + params.cityCode + '&areaCode=' + params.areaCode + '&companyId=' + params.companyId + '&otherCompanyName=' + params.otherCompanyName + '&name=' + params.name + '&phone=' + params.phone)
}

// 新增收货地址
export const addAddressApi = params => {
  return axios.post('/MID_/companyAddress/add', params)
}

// 前台首页热门商品
export const indexHotShopListApi = params => {
  return axios.get('/product/popular/list')
}

// 前台首页热门商品立即报价
export const indexNowQuoteApi = params => {
  return axios.post('/product/popular/atOnce/' + params.recommendId, params.result)
}


const ChannelEnum = {
  // 中台
  GATHER: "GATHER",
  // 后台
  GATHER_BOOT: "GATHER_BOOT",
  // 小程序
  GATHER_MINI: "GATHER_MINI",
  // H5
  GATHER_H5: "GATHER_H5",
  // APP
  GATHER_APP: "GATHER_APP"
}
