<template>
  <div class="home" @scroll="handleScroll">
    <Topbar @showDialogRequest="enquiryType = true"></Topbar>
    <header>
      <div class="wrap">
        <div class="leftBox">
          <p>集中采购，优质合作商</p>
          <h2>提供更好的服务</h2>
          <!-- <el-button class="lightDeepStyle">了解更多</el-button> -->
          <div class="overlay">
            <div>
              <div class="overlayImg">
                <img src="../../assets/img/index1.png" alt="" />
              </div>
              <div class="overlayBox">
                <p>3亿+</p>
                <span>近一年平台采购规模</span>
              </div>
            </div>
            <div>
              <div class="overlayImg">
                <img src="../../assets/img/index2.png" alt="" />
              </div>
              <div class="overlayBox">
                <p>8千+</p>
                <span>近一年累计询价单</span>
              </div>
            </div>
            <div>
              <div class="overlayImg">
                <img src="../../assets/img/index3.png" alt="" />
              </div>
              <div class="overlayBox">
                <p>1132家</p>
                <span>入驻采购供应商</span>
              </div>
            </div>
          </div>
        </div>
        <div class="rightBox">
          <div class="top">
            <h4>
              <p>Hi, 您好！</p>
              <el-button v-if="token !== null && token !== ''" @click="loginOut">退出登录</el-button>
            </h4>
            <div class="loginDiv" v-if="token == null || token == ''">
              <button @click="$router.push({ name: 'login' })">登录</button>
              <button @click="$router.push({ name: 'login', query: { type: 2 } })">免费注册</button>
            </div>
            <div class="isLoginDiv" v-else>
              <h4>{{ userName }}</h4>
              <el-tooltip v-if="companyName.length > 15" class="item" effect="dark" :content="companyName"
                placement="top-start">
                <p class="textHidden">{{ companyName }}</p>
              </el-tooltip>
              <p v-else class="textHidden">{{ companyName }}</p>
              <i class="el-icon-success"></i>
            </div>
          </div>
          <el-button v-if="token == null || token == ''" @click="goError" class="centerBtn"><i
              class="el-icon-data-line"></i>企业中心<i class="el-icon-caret-right"></i></el-button>
          <el-button v-else @click="goSuccess" class="centerBtn"><i class="el-icon-data-line"></i>企业中心<i
              class="el-icon-caret-right"></i></el-button>
          <div class="down">
            <p>采购询价</p>
            <div class="downs">
              <div @click="routerJump(1, 1)">
                <img src="../../assets/img/home1.png" alt="" />
                <i v-if="askNumber !== '' && askNumber[2].count != 0">{{ askNumber[2].count }}</i>
                <p>待审核</p>
              </div>
              <div @click="routerJump(1, 2)">
                <img src="../../assets/img/home2.png" alt="" />
                <i v-if="askNumber !== '' && askNumber[3].count != 0">{{ askNumber[3].count }}</i>
                <p>询价中</p>
              </div>
            </div>
          </div>
          <div class="down">
            <p style="margin-top: 0px">销售报价</p>
            <div class="downs">
              <div @click="routerJump(2, 1)">
                <img src="../../assets/img/home3.png" alt="" />
                <i v-if="askNumber2 !== '' && askNumber2[1].count != 0">{{ askNumber2[1].count }}</i>
                <p>待报价</p>
              </div>
              <div @click="routerJump(2, 2)">
                <img src="../../assets/img/home4.png" alt="" />
                <i v-if="askNumber2 !== '' && askNumber2[2].count != 0">{{ askNumber2[2].count }}</i>
                <p>已报价</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <main @click="showDialog">
    </main>
    <article v-if="indexHotShopList.list != null">
      <div class="wrap">
        <h3>
          <p>{{ indexHotShopList.title }}</p>
          <ul>
            <li @click="chooseIndexHotShop = index" v-for="(item, index) in indexHotShopList.list" :key="index"
              :class="chooseIndexHotShop == index ? 'chooseLi' : ''">{{ item.title }}</li>
          </ul>
        </h3>
        <div class="hotList">
          <div v-for="(item, index) in indexHotShopList.list[chooseIndexHotShop]?.list" class="hotBox">
            <img
              :src="item.images !== null && item.images !== '' ? item.images?.[0].url : require('../../assets/img/noImg.png')">
            <h6 class="textHidden">{{ item.productName }}</h6>
            <p><span>数&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;量:</span><span>10</span></p>
            <p><span>单&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;位:</span><span>{{ item.productUnit == '' ?
              '-' : item.productUnit }}</span></p>
            <p><span>商品规格:</span><span>{{ item.productSpecs == '' ? '-' : item.productSpecs }}</span></p>
            <p><span>商品型号:</span><span>{{ item.productModel == '' ? '-' : item.productModel }}</span></p>
            <el-button @click="nowQuote(item)">立即询价</el-button>
          </div>
        </div>
      </div>
    </article>
    <section v-if="refresh === true">
      <div class="wrap">
        <h3>
          <p>热门订单</p>
          <span @click="getHotShopList(2)" :class="newType == 2 ? 'showCol' : ''">询价列表</span>
          <span @click="getHotShopList(3)" :class="newType == 3 ? 'showCol' : ''">竞价列表</span>
          <span @click="getHotShopList(5)" :class="newType == 5 ? 'showCol' : ''">拍卖列表</span>
        </h3>
        <div class="listBox">
          <div v-if="newType === 2" class="allLabel">
            <div class="labelBox" v-for="(item, index) in hotShopList" :key="index" @click="goDetail(item, 1)">
              <div class="labelLeft">
                <img :src="item.imgUrl === null ? require('../../assets/img/noImg.png') : item.imgUrl" alt="">
              </div>
              <div class="labelMiddle">
                <h4 class="h4Class textHidden2">
                  <p>{{ item.productName }}</p>
                  <p><span>单位: <i v-if="item.productUnit">{{ item.productUnit }}</i></span><span>单次采购数量: <i
                        v-if="item.number">{{ item.number }}</i></span></p>
                </h4>
                <p>询价标题: {{ item.name == null ? '暂无' : item.name }}</p>
                <div class="mainContent">
                  <div class="mainBox">
                    <div class="mainImg"><img src="../../assets/img/index/index1.png" alt=""></div>
                    <div>
                      <p>发票要求</p><span>{{ item.invoiceType == 1 ? '不需要发票' : (item.invoiceType == 2 ? '增值税普通发票' :
                        '增值税专用发票') }}</span>
                    </div>
                  </div>
                  <div class="mainBox">
                    <div class="mainImg"><img src="../../assets/img/index/index2.png" alt=""></div>
                    <div>
                      <p>承运方</p><span>{{ item.carrier == 1 ? '需方' : '供方' }}</span>
                    </div>
                  </div>
                  <div class="mainBox">
                    <div class="mainImg"><img src="../../assets/img/index/index3.png" alt=""></div>
                    <div>
                      <p>支付方式</p>
                      <el-tooltip class="item" effect="dark"
                        :content="item.payType == 1 ? '款到发货' : (item.payType == 2 ? '定金' + item.subscriptionPer + '%, 发货前结尾款' : (item.payType == 3 ? '定金' + item.subscriptionPer + '%, 发货后尾款' : (item.payType == 4 ? '账期: ' + item.accountTime + '天' : (item.payType == 5 ? '货到付款' : '-'))))"
                        placement="bottom">
                        <span class="textHidden">{{ item.payType == 1 ? '款到发货' : (item.payType == 2 ? '定金' +
                          item.subscriptionPer + '%, 发货前结尾款' : (item.payType == 3 ? '定金' + item.subscriptionPer +
                            '%,发货后尾款' : (item.payType == 4 ? '账期: ' + item.accountTime + '天' : (item.payType == 5 ? '货到付款'
                              :
                              '-')))) }}</span>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="mainBox">
                    <div class="mainImg"><img src="../../assets/img/index/index4.png" alt=""></div>
                    <div>
                      <p>收货地址</p>
                      <span>{{ (item.addressProvinceName == null ? '-' : item.addressProvinceName) +
                        (item.addressCityName == null ? '' : item.addressCityName) + (item.addressAreaName == null ? ''
                          : item.addressAreaName) }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="labelRight">
                <div class="rightShow">
                  <p>报价倒计时</p>
                  <span>{{ item.countDown }}</span>
                </div>
                <el-button class="lightDeepStyle">查看详情</el-button>
                <p>报价企业: <span>{{ item.offerComCount }}</span>家</p>
              </div>
            </div>
          </div>
          <div v-if="newType == 3" class="allLabel2">
            <div class="labelBox" v-for="(item, index) in hotShopList" :key="index" @click="goDetail(item, 2)">
              <div class="labelLeft">
                <h4 class="h4Class textHidden2">
                  {{ item.name }}
                  <p>
                    <i v-if="item.numberRound != 1 && item.nowRound == 1">首轮</i>
                    <i v-else-if="item.numberRound != 1 && item.nowRound == 2">二轮</i>
                    <i v-else-if="item.numberRound != 1 && item.nowRound == 3">三轮</i>
                  </p>
                </h4>
                <div class="mainContent">
                  <div class="mainBox">
                    <div class="mainImg"><img src="../../assets/img/index/index1.png" alt=""></div>
                    <div>
                      <p>发票要求</p><span>{{ item.invoiceType == 1 ? '不需要发票' : (item.invoiceType == 2 ? '增值税普通发票' :
                        '增值税专用发票') }}</span>
                    </div>
                  </div>
                  <div class="mainBox">
                    <div class="mainImg"><img src="../../assets/img/index/index2.png" alt=""></div>
                    <div>
                      <p>承运方</p><span>{{ item.carrier == 1 ? '需方' : '供方' }}</span>
                    </div>
                  </div>
                  <div class="mainBox">
                    <div class="mainImg"><img src="../../assets/img/index/index3.png" alt=""></div>
                    <div>
                      <p>支付方式</p>
                      <el-tooltip class="item" effect="dark"
                        :content="item.payType == 1 ? '款到发货' : (item.payType == 2 ? '定金' + item.subscriptionPer + '%, 发货前结尾款' : (item.payType == 3 ? '定金' + item.subscriptionPer + '%, 发货后尾款' : (item.payType == 4 ? '账期: ' + item.accountTime + '天' : (item.payType == 5 ? '货到付款' : '-'))))"
                        placement="bottom">
                        <span class="textHidden">{{ item.payType == 1 ? '款到发货' : (item.payType == 2 ? '定金' +
                          item.subscriptionPer + '%, 发货前结尾款' : (item.payType == 3 ? '定金' + item.subscriptionPer +
                            '%,发货后尾款' : (item.payType == 4 ? '账期: ' + item.accountTime + '天' : (item.payType == 5 ? '货到付款'
                              :
                              '-')))) }}</span>
                      </el-tooltip>
                    </div>
                  </div>
                  <div class="mainBox">
                    <div class="mainImg"><img src="../../assets/img/index/index4.png" alt=""></div>
                    <div>
                      <p>收货地址</p>
                      <span>{{ (item.addressProvinceName == null ? '-' : item.addressProvinceName) +
                        (item.addressCityName == null ? '' : item.addressCityName) + (item.addressAreaName == null ? ''
                          : item.addressAreaName) }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="labelRight">
                <div class="rightShow">
                  <p>报价倒计时</p>
                  <span>{{ item.countDown }}</span>
                </div>
                <el-button class="lightDeepStyle">查看详情</el-button>
                <p>报价企业: <span>{{ item.offerComCount }}</span>家</p>
              </div>
            </div>
          </div>
          <div v-if="newType == 5" class="allLabel3">
            <div class="labelBox" v-for="(item, index) in hotShopList" :key="index" @click="goDetail(item, 3)">
              <div class="labelLeft">
                <img :src="item.imgUrl === null ? require('../../assets/img/noImg.png') : item.imgUrl" alt="">
              </div>
              <div class="labelMiddle">
                <h4 class="h4Class textHidden2">
                  <p>{{ item.productName }} <span>当前价格: <i>{{ !item.maxPriceExt == null ? item.startPriceExt :
                    item.maxPriceExt }}</i></span></p>
                  <p><span>数量: <i>{{ item.number }}</i></span><span>单位: <i>{{ item.productUnit }}</i></span>
                    <span>起拍价: <i>{{ item.startPriceExt }}</i></span>
                    <span>每次加价: <i>{{ item.oncePriceExt }}</i></span>
                  </p>
                </h4>
                <div class="mainContent">
                  <p>标题: {{ item.name == null ? '暂无' : item.name }}</p>
                  <p>
                    <span>发票要求: <i>{{ item.invoiceType == 1 ? '不需要发票' : (item.invoiceType == 2 ? '增值税普通发票' :
                      '增值税专用发票') }}</i></span>
                    <span>承运方: <i>{{ item.carrier == 1 ? '需方' : '供方' }}</i></span>
                    <span>结算方式: <i>{{ item.payType == 1 ? '款到发货' : (item.payType == 2 ? '定金' + item.subscriptionPer +
                      '%,发货前结尾款' : (item.payType == 3 ? '定金' + item.subscriptionPer + ' %, 发货后尾款' : (item.payType == 4
                        ?
                        '账期: ' + item.accountTime + '天' : (item.payType == 5 ? '货到付款' : '-')))) }}</i></span>
                    <span>发货地址: <i>{{ (item.addressProvinceName == null ? '-' : item.addressProvinceName) +
                      (item.addressCityName == null ? '' : item.addressCityName) + (item.addressAreaName == null ? ''
                        : item.addressAreaName) }}</i></span>
                  </p>
                </div>
              </div>
              <div class="labelRight">
                <div class="rightShow">
                  <p>报价倒计时</p>
                  <span>{{ item.countDown }}</span>
                </div>
                <el-button class="lightDeepStyle">查看详情</el-button>
                <p>报价企业: <span>{{ item.offerComCount }}</span>家</p>
              </div>
            </div>
          </div>
          <div @click="routerPush(1)" class="jumpBox" v-if="newType == 2 && hotShopList.length !== 0">
            更多询价</div>
          <div @click="routerPush(2)" class="jumpBox" v-if="newType == 3 && hotShopList.length !== 0">更多竞价</div>
          <div @click="routerPush(3)" class="jumpBox" v-if="newType == 5 && hotShopList.length !== 0">更多拍卖</div>
        </div>
      </div>
    </section>
    <!-- <main v-if="token == null || token == ''" class="main2" @click="routerPush(4)"></main>
    <main v-else class="main2" @click="goSuccess()"></main> -->
    <!-- 活跃企业 -->
    <div class="activeBox">
      <div class="wrap">
        <h3>合作企业</h3>
        <div class="logoBox">
          <p>石油化工行业</p>
          <div>
            <img src="../../assets/img/companyLogo/01.png" alt="" />
            <img src="../../assets/img/companyLogo/02.png" alt="" />
            <img src="../../assets/img/companyLogo/03.png" alt="" />
            <img src="../../assets/img/companyLogo/04.png" alt="" />
            <img src="../../assets/img/companyLogo/05.png" alt="" />
            <img src="../../assets/img/companyLogo/06.png" alt="" />
          </div>
          <p>汽车行业</p>
          <div>
            <img src="../../assets/img/companyLogo/11.png" alt="" />
            <img src="../../assets/img/companyLogo/12.png" alt="" />
            <img src="../../assets/img/companyLogo/13.png" alt="" />
            <img src="../../assets/img/companyLogo/14.png" alt="" />
            <img src="../../assets/img/companyLogo/15.png" alt="" />
            <img src="../../assets/img/companyLogo/16.png" alt="" />
          </div>
          <p>电气制造业</p>
          <div>
            <img src="../../assets/img/companyLogo/21.png" alt="" />
            <img src="../../assets/img/companyLogo/22.png" alt="" />
            <img src="../../assets/img/companyLogo/23.png" alt="" />
            <img src="../../assets/img/companyLogo/24.png" alt="" />
            <img src="../../assets/img/companyLogo/25.png" alt="" />
            <img src="../../assets/img/companyLogo/26.png" alt="" />
          </div>
          <p>矿业行业</p>
          <div>
            <img src="../../assets/img/companyLogo/31.png" alt="" />
            <img src="../../assets/img/companyLogo/32.png" alt="" />
            <img src="../../assets/img/companyLogo/33.png" alt="" />
            <img src="../../assets/img/companyLogo/34.png" alt="" />
            <img src="../../assets/img/companyLogo/35.png" alt="" />
            <img src="../../assets/img/companyLogo/36.png" alt="" />
          </div>
          <p>建筑建材行业</p>
          <div>
            <img src="../../assets/img/companyLogo/41.png" alt="" />
            <img src="../../assets/img/companyLogo/42.png" alt="" />
            <img src="../../assets/img/companyLogo/43.png" alt="" />
            <img src="../../assets/img/companyLogo/44.png" alt="" />
          </div>
          <p>消费电子行业</p>
          <div>
            <img src="../../assets/img/companyLogo/51.png" alt="" />
            <img src="../../assets/img/companyLogo/52.png" alt="" />
            <img src="../../assets/img/companyLogo/53.png" alt="" />
            <img src="../../assets/img/companyLogo/54.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Topbar from '@/components/TopbarVue.vue'
import { setCookie, clearCookie } from '@/utils/cookies'
import bus from '../../EventBus'
import {
  askPriceShopListApi,
  userInfoApi,
  loginOutApi,
  biddingListApi,
  askPriceNumberApi,
  sellAskPriceNumberApi,
  auctionListApi,
  indexHotShopListApi,
  indexNowQuoteApi
} from '@/api/index'
import axios from 'axios'
export default {
  components: {
    Topbar,
  },
  props: {},
  data() {
    return {
      // 热门商品列表
      indexHotShopList: [],
      chooseIndexHotShop: '',
      askNumber: '',
      askNumber2: '',
      refresh: true,
      newType: 2,
      newType2: 1,
      companyShowData: {
        title: '吉利控股集团',
        content1:
          '浙江吉利控股集团始建于1986年，1997年进入汽车行业，一直专注实业，专注技术创新和人才培养，不断打基础练内功，坚定不移地推动企业健康可持续发展。现资产总值约3300亿元，员工总数超过12万人，连续七年进入全国企业500强。2018年，吉利控股集团旗下各品牌车型累计销量超215万辆，同比增长18.3%，四年销量翻番，实现跨越式发展。',
        content2:
          '吉利造车25年来，吉利汽车品牌使命历经 “造老百姓买得起的好车”、“造最安全、最环保、最节能的好车”、“造每个人的精品车”三个发展阶段的变化，但却始终以用户为中心，为用户造车。',
        logo: '',
        img: 'jili.jpeg',
      },
      companyChoose: 0,
      // 优质采购商数据
      highList: [
        {
          title: '吉利控股集团',
          content1:
            '浙江吉利控股集团始建于1986年，1997年进入汽车行业，一直专注实业，专注技术创新和人才培养，不断打基础练内功，坚定不移地推动企业健康可持续发展。现资产总值约3300亿元，员工总数超过12万人，连续七年进入全国企业500强。2018年，吉利控股集团旗下各品牌车型累计销量超215万辆，同比增长18.3%，四年销量翻番，实现跨越式发展。',
          content2:
            '吉利造车25年来，吉利汽车品牌使命历经 “造老百姓买得起的好车”、“造最安全、最环保、最节能的好车”、“造每个人的精品车”三个发展阶段的变化，但却始终以用户为中心，为用户造车。',
          logo: '',
          img: 'jili.jpeg',
        },
        {
          title: '苏泊尔',
          content1:
            '全球知名小家电及炊具制造集团；全球40个工业基地，全球33,000余名员工；苏泊尔是知名的炊具、小家电的领导品牌，于2004年在深交所上市（股票代码002032），是中国炊具行业首家上市公司。成立于1994年，6大研发制造基地：杭州、武汉、绍兴、玉环、柯桥、越南；15000 余项专利技术（截至2022年2月底）；12,000 多名员工；100,000,000 件的年产能',
          content2:
            '承担责任，延伸企业美德，助推和谐社会营造，是苏泊尔作为企业公民义不容辞的社会责任。一直以来，苏泊尔不仅为消费者提供优良的产品和服务，更热衷公益活动，在发展交通、关爱老人、支持教育、赈灾等公益事业方面，以实际行动真诚回报社会。',
          logo: '',
          img: 'shuboer.jpeg',
        },
        {
          title: '公牛集团',
          content1:
            '公牛集团自1995年创立以来，始终坚持以消费者需求为导向，以产品品质为根本，从“插座”这一细分领域开始，不断推动功能、技术与设计的创新，开发出了大批受消费者喜爱的新产品。以创新为灵魂，凭借产品研发、营销、供应链及品牌方面的综合领先优势，公司在多年的发展过程中逐步拓展、形成了电连接、智能电工照明、数码配件三大业务板块，围绕民用电工及照明领域形成了长期可持续发展的产业布局。',
          content2:
            '拥抱变化，笃行致远，公牛集团将一如既往地秉承“专业专注、只做第一、走远路”的经营理念，朝着“成为国际民用电工行业领导者”的愿景目标继续前进。',
          logo: '',
          img: 'gongniu.jpeg',
        },
        {
          title: '方太集团',
          content1:
            '方太把自身对世界厨房生活及厨房文化的深厚理解融入产品的设计研发中，并结合和利用人体功能学、工业美学、各种边缘科学以及集成技术，不断创造出饱含科技、设计领先、极富人性的厨房产品。同时，方太还凭借雄厚的技术实力、强大的营销能力，推动并引领整个厨房产业向"精工细作的现代工业"转化。',
          content2:
            '方太集团（以下简称“方太”）创建于1996年。作为一家以智能厨电为核心业务的幸福生活解决方案提供商，方太长期致力于为人们提供高品质的产品和服务，打造健康环保有品位有文化的生活方式，让千万家庭享受更加幸福安心的生活。',
          logo: '',
          img: 'fangtai.jpeg',
        },
        {
          title: '正泰集团',
          content1:
            '正泰集团股份有限公司始创于1984年，是全球知名的智慧能源系统解决方案提供商。创立39年来，正泰始终聚精会神干实业、一门心思创品牌，深入践行“产业化、科技化、国际化、数字化、平台化”战略举措，形成了“绿色能源、智能电气、智慧低碳”三大板块和“正泰国际、科创孵化”两大平台，业务遍及140多个国家和地区，全球员工4万余名，2022年集团营业收入1237亿元，连续二十余年上榜中国企业 500强。',
          content2:
            '正泰坚持以人为本、价值分享文化不动摇。以“让电力能源更安全、绿色、便捷、高效”为使命，“以客户为中心，创新、谦学、正直、协作、担当”为核心价值观，以“为客户创造价值，为员工谋求发展，为社会承担责任”为经营理念，与国内外合作伙伴及产业链上下游实现价值共享，构筑发展共同体。',
          logo: '',
          img: 'zhengtai.webp',
        },
        {
          title: '得力集团',
          content1:
            '得力集团创建于1982年，全球化文创科技产业集团多工作学习场景整体解决方案的倡导者和领导者旨在通过创新引领，持续为全球用户创造技术领先、品质卓越、体验友好让消费者心生情感认同的产品与服务，让工作生活更美好得力旗下涵盖得力办公、得力文具、安格耐特、纽赛、得力工具等独立子品牌产品线覆盖办公与学生文具、办公设备、办公耗材、办公纸品等二十四大门类并在多个细分领域连续多年保持领先；',
          content2:
            '得力通过持续不断的产品创新、管理创新和产业布局，在业内建立了中国第一的市场地位，并成为全球拥有广泛知名度的办公文具品牌。丰富而完整的产品集群，全方位的满足了企业级用户一站式整体采购的消费需求，同时，也给更多的个人消费提供了多样化与个性化的产品选择。',
          logo: '',
          img: 'deli.webp',
        },
      ],
      itemWidth: 210, // 企业盒子的宽度
      currentOffset: 0, // 当前偏移量
      // ------------------------------------
      enquiryType: false,
      userName: '',
      companyName: '',
      token: '',
      //咨询切换
      pressTypeList: [],
      // 新闻数据
      pressList: [],
      params: {
        size: 10,
        current: 1,
        typeId: '',
        recSite: '',
      },
      focusMsgList: [],
      focusedInput: null,
      enquiryData: {
        name: '',
        industryName: '',
        number: '',
        unit: '',
        phone: '',
      },
      // 分类
      classifyIndex: null,
      // 普通数据
      hotShopList: [],
      // 热门数据
      hotShopList2: [],
      showLevel2: [],
      showLevel3: [], // 记录三级盒子的显示状态
      // 优质采购商下标
      currentIndex: '',
      // 行业咨询标题数据
      titles: [
        { id: 1, text: 'Title 1' },
        { id: 2, text: 'Title 2' },
        { id: 3, text: 'Title 3' },
        // ... more titles
      ],
      // 行业咨询标题数据
      contents: [
        {
          id: 1,
          image: ['a 1.jpg', 'a 2.jpg', 'a 3.jpg'],
          names: ['Name 1', 'Name 2', 'Name 3'],
        },
        {
          id: 2,
          image: ['b 4.jpg', 'b 5.jpg', 'b 6.jpg'],
          names: ['Name 4', 'Name 5', 'Name 6'],
        },
        {
          id: 3,
          image: ['c 7.jpg', 'c 8.jpg', 'c 9.jpg'],
          names: ['Name 7', 'Name 8', 'Name 9'],
        },
      ],
      // 行业咨询标题数据
      selectedTitle: null,
      selectedImg: null,
      selectedIndex: 0,
      originalText: '浙江小蚁科技有限公司',
      // 优质采购商轮播切换
      carouselIndex: 0,
      companyInfoArr: [
        {
          name: '苏泊尔',
        },
      ],
      // 是否为地平线
      companyStatus: '0'
    }
  },
  computed: {
    groupedHotShopList() {
      const length = this.hotShopList2.length
      // 如果数据条数已经是 3 的倍数，则直接按 3 个分组
      if (length % 3 === 0) {
        return this.hotShopList2.reduce((result, item, index) => {
          if (index % 3 === 0) result.push([])
          result[result.length - 1].push(item)
          return result
        }, [])
      }
      // 计算需要填充的数据条数
      const extraItemsCount = (3 - (length % 3)) % 3
      const extendedList = [...this.hotShopList2]
      // 根据需要填充额外数据，这里使用 null 作为示例
      if (extraItemsCount > 0) {
        extendedList.push(...Array(extraItemsCount).fill(null))
      }
      // 按照每组 3 个进行分组，并过滤掉 null
      return extendedList.reduce((result, item, index) => {
        if (index % 3 === 0) result.push([])
        if (item !== null) result[result.length - 1].push(item)
        return result
      }, [])
    },
    maxOffset() {
      return (this.highList.length - 1) * this.itemWidth - 1000
    },
    // 星号替换
    maskedText() {
      const text = this.originalText
      const middleStart = Math.floor((text.length - 2) / 2)
      const maskedPart = text
        .substring(middleStart, middleStart + 2)
        .replace(/./g, '*')
      return (
        text.substring(0, middleStart) +
        maskedPart +
        text.substring(middleStart + 2)
      )
    },
    // 优质采购商
    displayedItems() {
      const displayed = []
      for (let i = this.currentIndex; i < this.currentIndex + 5; i++) {
        displayed.push(this.highList[i % this.highList.length])
      }
      return displayed
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.query !== from.query) {
      this.refresh = false
      setTimeout(() => {
        this.refresh = true
        if (this.$route.query.type == 2) {
          this.getHotShopList(3)
        }
      }, 100)
    }
    next()
  },
  created() {
    this.token = window.localStorage.getItem('token')
    this.getUserInfo()
    this.getAskPriceNumber()
    this.getSellAskPriceNumber()
    this.getAllHotShopList()
  },
  mounted() {
    if (this.$route.query.type == 2) {
      this.getHotShopList(3)
    } else {
      this.getHotShopList(2)
    }
    // 添加滚动事件监听器
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    // 移除滚动事件监听器
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    // 立即报价
    nowQuote(result) {
      // 判断当前商品是否已经询过价
      if (result.enquiry == true) {
        this.$confirm('当前商品已经询过价,是否查看询价单详情', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$router.push({ path: '/productdetail', query: { id: result.enquiryId, proId: result.enquiryLineId } })
        })
      } else {
        this.$confirm('是否确认立即询价？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const { data: res } = await indexNowQuoteApi({ result: result, recommendId: this.indexHotShopList.recommendId })
          if (res.code !== 200) return this.$message.error(res.message)
          this.$message.success('询价成功!')
          this.getAllHotShopList()
        }).catch(() => { })
      }
    },
    // 获取所有热门商品
    async getAllHotShopList() {
      const { data: res } = await indexHotShopListApi()
      if (res.code !== 200) return this.$message.error(res.message)
      this.indexHotShopList = res.result
      if (this.chooseIndexHotShop == '') {
        this.chooseIndexHotShop = 0
      }
    },
    handleScroll(event) {
      // 处理滚动事件
      if (window.scrollY == 0) {
        this.$store.commit('setClassifyBox', true)
      } else {
        this.$store.commit('setClassifyBox', false)
      }
    },
    // 查询报价单数量
    async getSellAskPriceNumber() {
      if (this.token !== null && this.token !== '') {
        const { data: res } = await sellAskPriceNumberApi(1)
        this.askNumber2 = res.result
      } else {
        return false
      }
    },
    // 查询询价单数量
    async getAskPriceNumber() {
      if (this.token !== null && this.token !== '') {
        const { data: res } = await askPriceNumberApi(1)
        this.askNumber = res.result
      } else {
        return false
      }
    },
    showDialog() {
      bus.$emit('requestShowDialog')
    },
    //退出登录
    async loginOut() {
      const { data: res } = await loginOutApi()
      if (res.code !== 200) return this.$message.error(res.message)
      localStorage.clear()
      location.reload()
    },
    async getUserInfo() {
      if (window.localStorage.getItem('token') == null) {
        this.companyName = ''
      } else {
        const { data: userInfo } = await userInfoApi()
        if (userInfo.code !== 200) return this.$message.error(userInfo.message)
        localStorage.setItem('userInfo', JSON.stringify(userInfo.data))
        this.companyName = userInfo.data.companyName
        this.userName = userInfo.data.name
        this.companyStatus = userInfo.data.companyStatus
      }
    },
    goError() {
      this.$message.error('请登录后再前往企业中心!')
    },
    goSuccess() {
      const baseUrl = this.companyStatus === '1' ? process.env.VUE_APP_REDIRECT_URL : process.env.VUE_APP_BASE_URL
      const token = this.token // 替换为实际的令牌值
      clearCookie('token')
      setCookie('token', token)
      const link = `${baseUrl}#/home`
      window.open(link, '_self')
    },
    editShowCompany(item, index) {
      this.companyChoose = index
      this.companyShowData = item
      console.log(this.companyShowData, 6)
    },
    moveLeft() {
      console.log(this.currentOffset, this.maxOffset)
      if (this.currentOffset > 0) {
        this.currentOffset -= this.itemWidth + 20
      } else {
        this.currentOffset = 0
      }
    },
    moveRight() {
      console.log(this.currentOffset, this.maxOffset)
      if (this.currentOffset < 0) {
        this.currentOffset = 0
      } else if (this.currentOffset < this.maxOffset) {
        this.currentOffset += this.itemWidth + 20
      }
    },
    routerPush(val) {
      if (val == 1) {
        this.$router.push({ path: '/product' })
      } else if (val == 2) {
        this.$router.push({ path: '/bidding' })
      } else if (val == 3) {
        this.$router.push({ path: '/auction' })
      } else if (val == 4) {
        this.$router.push({ path: '/login' })
      }
    },
    // 路由跳转
    routerJump(val, key) {
      const baseUrl = this.companyStatus === '1' ? process.env.VUE_APP_REDIRECT_URL : process.env.VUE_APP_BASE_URL
      if (this.companyStatus === '1') {
        window.open(`${baseUrl}`)
      }
      const token = window.localStorage.getItem('token')
      if (token == null) {
        this.$message.error('请登录后再前往企业中心!')
      } else {
        clearCookie('token')
        setCookie('token', token)
        if (val === 1) {
          if (key == 1) {
            window.open(`${baseUrl}#/askPricePurchase?state=4`)
          } else if (key == 2) {
            window.open(`${baseUrl}#/askPricePurchase?state=2`)
          }
        } else if (val === 2) {
          if (key == 1) {
            window.open(`${baseUrl}#/askPriceSell?indexState=1`)
          } else if (key == 2) {
            window.open(`${baseUrl}#/askPriceSell?indexState=2`)
          }
        }
      }
    },
    focusInput(fieldName) {
      this.focusedInput = fieldName
    },
    blurInput() {
      this.focusedInput = null
    },
    // 报价
    goDetail(result, val) {
      if (val == 1) {
        this.$router.push({ path: '/productdetail', query: { id: result.enquiryId, proId: result.id } })
      } else if (val == 2) {
        this.$router.push({ path: '/biddingdetail', query: { id: result.id, proId: '' } })
      } else {
        this.$router.push({ path: '/auctionDetail', query: { id: result.id, proId: '' } })
      }
    },
    goDetailMsg(id) {
      this.$router.push({
        path: '/industrydetail',
        query: {
          id: id,
        },
      })
    },
    async getHotShopList(value) {
      if (value === 2) {
        // 获取最新询价数据
        const { data: res2 } = await askPriceShopListApi({ name: '', proName: '', current: 1, size: 6, addressProvinceId: '', addressCityId: '', addressAreaId: '', beginTime: '', endTime: '', industryId: '', state: '2', timeType: 0, type: 1 })
        this.hotShopList = res2.result.records
        this.newType = value
      } else if (value === 3) {
        // 获取竞价采购数据
        const { data: res3 } = await biddingListApi({ name: '', proName: '', current: 1, size: 6, addressProvinceId: '', addressCityId: '', addressAreaId: '', beginTime: '', endTime: '', industryId: '', state: '2', timeType: 0, type: 2, round: '' })
        this.hotShopList = res3.result.records
        this.newType = value
      } else if (value === 5) {
        // 获取最新拍卖列表数据
        const { data: res5 } = await auctionListApi({ current: 1, size: 6, isHot: '' })
        this.hotShopList = res5.result.records
        this.newType = value
      }
    },
    // echarts
    getRenderer() {
      // 基于准备好的dom，初始化echarts实例
      const EChart = this.$echarts.init(document.getElementById('mains'))
      // 配置参数
      const dataMax = [
        { name: '商务', max: '100' },
        { name: '技术', max: '100' },
        { name: '质量', max: '100' },
        { name: '服务', max: '102' },
        { name: '客户满意度', max: '100' },
      ]
      const option = {
        // 配置维度的最大值
        radar: [
          {
            indicator: dataMax,
            center: ['50%', '50%'],
            radius: '60%',
            splitLine: {
              // 配置雷达图的每一圈的网格线颜色
              lineStyle: {
                color: 'rgba(215, 23, 24, 0.10)',
              },
            },
            splitArea: {
              // 配置雷达图的网格线背景
              show: false,
            },
            name: {
              // 配置雷达图的每个指示器的名称颜色
              textStyle: {
                color: 'black',
              },
            },
            axisLine: {
              show: false,
            },
          },
        ],
        series: [
          {
            type: 'radar',
            label: {
              show: false, // 显示数值
            },
            itemStyle: {
              // 此属性的颜色和下面areaStyle属性的颜色都设置成相同色即可实现
              color: 'white',
              borderColor: 'rgba(198, 49, 39, 1)',
            },
            data: [
              {
                name: '华为手机',
                value: [80, 90, 80, 82, 90],
                areaStyle: {
                  color: 'rgba(198, 49, 39, 1)',
                },
                lineStyle: {
                  color: 'rgba(198, 49, 39, 1)',
                  width: 3,
                },
              },
            ],
          },
        ],
      }
      // 设置参数
      EChart.setOption(option)
    },
  },
}
</script>
<style scoped lang="less">
.home {

  // padding-top: -40px
  /* 滚动条优化 start */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #f6f6f6;
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background: #cdcdcd;
    border-radius: 2px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #747474;
  }

  ::-webkit-scrollbar-corner {
    background: #f6f6f6;
  }

  /* 滚动条优化 end */
  .model {
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.7);
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    .enquiry {
      z-index: 99999;
      width: 435px;
      height: 452px;
      background: #ffffff;
      border-radius: 16px 16px 16px 16px;
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
      opacity: 1;
      position: fixed;
      bottom: 22%;
      right: 40%;
      padding: 10px 20px 50px 20px;

      .btn {
        p {
          margin: 0 auto;
          width: 235px;
          height: 40px;
          background: #c63127;
          border-radius: 8px 8px 8px 8px;
          cursor: pointer;
          opacity: 1;
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #fff;
          line-height: 40px;
        }

        p:hover {
          background-color: rgba(198, 49, 39, 0.7);
        }
      }

      h1 {
        padding-top: 20px;
        text-align: center;
        width: 435px;
        height: 68px;
        font-size: 24px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
        line-height: 28px;
        margin: 0;
      }

      p {
        text-align: center;
        // width: 144px;
        height: 22px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        line-height: 19px;
        margin: 0;
      }

      .form-input {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
      }

      .units {
        display: flex;

        input:nth-child(1) {
          margin-right: 10px;
        }

        input {
          width: 100px;
          height: 48px;
        }
      }

      .input-group {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }

      input {
        height: 48px;
        // background: rgba(255,255,255,0.8);
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        flex: 1;
        border: none;
        border-bottom: 2px solid transparent;
        background-color: #f6f6f6;
        transition: background-color 0.3s, border-color 0.3s;
        padding: 5px;
        font-size: 16px;
        padding-left: 15px;
      }

      input:focus {
        border-color: red;
        background-color: white;
      }

      // input[type="text"]:not(:placeholder-shown) + label,
      // input[type="text"]:focus + label {
      //   transform: translateY(-20px);
      //   font-size: 12px;
      // }
    }
  }

  header {
    height: 480px;
    padding-top: 90px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    background-size: cover;
    background-image: url('../../assets/img/headerBg.png');

    .wrap {
      display: flex;

      .leftBox {
        position: relative;
        width: calc(100% - 240px);
        height: 380px;

        >p {
          margin-top: 30px;
          font-size: 24px;
          color: rgba(24, 144, 255, 1);
          font-weight: bold;
        }

        h2 {
          margin: 16px 0;
          font-size: 48px;
          line-height: 48px;
          color: rgba(24, 144, 255, 1);
        }

        .el-button {
          padding: 6px 16px;
          height: 32px;
          border-radius: 0;
          font-size: 14px;
          font-weight: bold;
          font-family: 'SourceHanSancCN';
        }

        .overlay {
          position: absolute;
          bottom: 30px;
          left: 0;
          display: flex;
          width: 636px;
          height: 72px;
          /* 调整半透明盒子的高度 */
          background: rgba(255, 255, 255, 0.5);
          border-radius: 16px;
          z-index: 99;

          >div {
            flex: 1;
            padding: 16px 0 8px 24px;
            display: flex;

            .overlayImg {
              padding: 7px;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              border: 1px solid rgba(24, 144, 255, 1);
              box-sizing: border-box;
              -moz-box-sizing: border-box;

              img {
                width: 24px;
                height: 24px;
              }
            }

            .overlayBox {
              padding-left: 17px;

              p {
                color: rgba(24, 144, 255, 1);
                font-size: 20px;
                font-weight: bold;
                font-family: 'SourceHanSancCN';
                line-height: 20px;
              }

              span {
                color: rgba(0, 0, 0, 0.40);
                font-size: 12px;
                font-weight: bold;
                font-family: 'SourceHanSancCN';
              }
            }
          }
        }
      }

      .rightBox {
        margin-top: 10px;
        width: 240px;
        height: 352px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        background-color: rgba(255, 255, 255, .7);
        background-size: 100% 100%;
        background-image: url('../../assets/img/headerRightBg.png');
        z-index: 99;

        .top {
          width: 100%;
          height: 108px;
          border-radius: 8px 8px 0px 0px;

          h4 {
            display: flex;
            justify-content: space-between;

            p {
              padding-left: 20px;
              font-size: 14px;
              font-weight: bold;
              color: #000000;
              line-height: 16px;
            }

            .el-button {
              margin-top: -3px;
              margin-right: 16px;
              padding: 4px 8px;
              width: 68px;
              height: 24px;
              border: 1px solid rgba(24, 144, 255, 1);
              box-sizing: border-box;
              -moz-box-sizing: border-box;
              font-family: 'SourceHanSancCN';
              font-weight: bold;
              font-size: 12px;
              color: rgba(24, 144, 255, 1);
            }
          }

          .loginDiv {
            height: 40px;
            box-sizing: border-box;
            -moz-box-sizing: border-box;

            button:nth-child(1) {
              /* 第一个按钮样式 */
              margin-left: 16px;
              margin-right: 16px;
              width: 96px;
              height: 40px;
              background: white;
              /* 保持白色背景 */
              border-radius: 4px;
              border: 1px solid rgba(24, 144, 255, 1);
              cursor: pointer;
              font-weight: bold;
              font-size: 15px;
              font-family: 'SourceHanSancCN';
              color: rgba(24, 144, 255, 1);
              line-height: 30px;
            }

            button:nth-child(1):hover {
              background: linear-gradient(to right,
                  rgba(24, 144, 2559, 1),
                  rgba(24, 144, 255, 1));
              color: white;
            }

            button:nth-child(2) {
              /* 第二个按钮样式 */
              width: 96px;
              height: 40px;
              background: linear-gradient(to right,
                  rgba(24, 144, 255, 1),
                  rgba(24, 144, 255, 1));
              cursor: pointer;
              color: white;
              border-radius: 4px;
              border: 1px solid rgba(24, 144, 255, 1);
              line-height: 30px;
              font-weight: bold;
              font-family: 'SourceHanSancCN';
              font-size: 15px;
            }

            button:nth-child(2):hover {
              background: linear-gradient(to right,
                  rgba(24, 144, 255, 0.7),
                  rgba(24, 144, 255, 0.7));
              color: white;
            }
          }

          .isLoginDiv {
            height: 50px;

            h4 {
              margin: -10px 0 10px;
              padding-left: 20px;
              font-size: 20px;
              font-weight: bold;
              color: #000000;
              line-height: 28px;
            }

            p {
              display: inline-block;
              width: 190px;
              padding-left: 20px;
              font-size: 12px;
              font-weight: bold;
              color: rgba(0, 0, 0, 0.6);
              vertical-align: top;
            }

            i {
              margin-top: 2px;
              margin-left: 3px;
              color: #62e4e4;
              vertical-align: top;
              font-size: 16px;
            }
          }
        }

        .centerBtn {
          margin: 10px 16px 0;
          width: 208px;
          height: 48px;
          background-color: rgba(24, 144, 255, 1);
          border-radius: 8px 8px 8px 8px;
          border: 1px solid transparent;
          font-size: 14px;
          color: #fff;
          font-weight: bold;
          font-family: 'SourceHanSancCN';
          text-align: left;

          i:nth-child(1) {
            margin-right: 8px;
            font-size: 14px;
          }

          i:nth-child(2) {
            float: right;
            font-size: 14px;
          }
        }

        .centerBtn:hover {
          opacity: 0.8;
        }

        .down {
          border-radius: 0px 0px 8px 8px;

          >p {
            margin: 14px 16px 8px;
            color: rgba(0, 0, 0, 0.4);
            font-size: 12px;
            font-weight: bold;
          }

          .downs {
            display: flex;
            width: 100%;
            height: 58px;
            justify-content: space-around;
            align-items: center;
            color: rgba(0, 0, 0, 0.8);

            >div {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 96px;
              height: 48px;
              background: rgba(255, 255, 255, 1);
              border-radius: 8px 8px 8px 8px;
              box-shadow: 0 3px 5px rgba(0, 0, 0, 0.03);
              cursor: pointer;
              font-size: 12px;
              text-align: center;

              img {
                margin-right: 4px;
                width: 24px;
                height: 24px;
              }

              p {
                margin: 0;
                line-height: 20px;
                font-weight: bold;
              }

              i {
                position: absolute;
                top: -4px;
                right: -2px;
                width: 16px;
                height: 16px;
                background: #FF0000;
                border-radius: 50%;
                font-size: 12px;
                color: #fff;
                line-height: 14px;
                font-weight: bold;
              }
            }

            >div:hover {
              transform: scale(1.05);
            }
          }

          .bottom {
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
              text-align: center;
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  main {
    margin-top: 40px;
    margin-bottom: 44px;
    margin-left: 50%;
    transform: translate(-50%);
    height: 120px;
    width: 1200px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    background-size: cover;
    background-image: url('../../assets/img/titleBox.png');
    cursor: pointer;
  }

  .main2 {
    margin-top: 40px;
    margin-bottom: 44px;
    margin-left: 50%;
    transform: translate(-50%);
    height: 120px;
    width: 1200px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    background-size: cover;
    background-image: url('../../assets/img/main2.png');
    cursor: pointer;
  }

  article {
    h3 {
      margin: 36px 0 16px;
      width: 100%;
      text-align: center;

      p {
        margin-bottom: 12px;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.80);
      }

      ul {
        display: flex;

        li {
          margin-right: 8px;
          padding: 0 10px;
          height: 40px;
          border-radius: 100px;
          cursor: pointer;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.4);
          line-height: 40px;
          font-size: 16px;
        }

        .chooseLi {
          background: #FFFFFF;
          color: rgba(24, 144, 255, 1);
        }
      }
    }

    .hotList {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .hotBox {
        margin-bottom: 24px;
        margin-right: 24px;
        padding: 16px;
        width: 282px;
        background: #FFFFFF;
        border-radius: 16px;
        box-sizing: border-box;

        img {
          width: 250px;
          height: 160px;
          border-radius: 16px;
          // border: 2px solid #F5F6F7;
        }

        h6 {
          margin: 6px 0 10px;
          font-size: 14px;
          font-weight: bold;
        }

        p {
          margin-bottom: 8px;
          color: rgba(0, 0, 0, 0.4);
          font-size: 14px;
          font-weight: bold;

          span:nth-child(1) {
            display: inline-block;
            width: 62px;
            text-align: justify;
          }
        }

        .el-button {
          width: 250px;
          height: 40px;
          background: #1890FF;
          border-radius: 8px;
          font-weight: bold;
          font-family: "SourceHanSancCN", "PingFang SC", miui, system-ui, -apple-system, BlinkMacSystemFont, Helvetica Neue, Helvetica, sans-serif;
          color: #fff;
        }

        .el-button:hover {
          background-color: rgba(24, 144, 255, .8);
        }
      }

      .hotBox:nth-child(4n) {
        margin-right: 0;
      }
    }
  }

  section {
    .wrap {
      position: relative;
    }

    h3 {
      margin: 33px 0 33px;
      width: 100%;
      text-align: center;

      p {
        margin-bottom: 12px;
        font-size: 24px;
        color: rgba(0, 0, 0, 0.80);
      }

      span {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.80);
        cursor: pointer;
      }

      span:nth-child(2) {
        margin-right: 20px;
      }

      span:nth-child(3) {
        margin-right: 20px;
      }

      .showCol {
        color: rgba(24, 144, 255, 1);
      }
    }

    .switchBox {
      position: absolute;
      top: 20px;
      left: 0;
      width: 176px;
      height: 32px;
      background: #FFFFFF;
      border-radius: 16px;
      line-height: 32px;

      p {
        display: inline-block;
        width: 88px;
        border-radius: 16px;
        cursor: pointer;
        text-align: center;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.80);
        font-weight: bold;
      }

      .showColor {
        background-color: rgba(24, 144, 255, 1);
        color: #fff;
      }
    }

    .titleBox {
      position: relative;
      margin: 36px 0 20px;
      display: flex;
      align-items: center;
      cursor: pointer;

      h1 {
        margin: 0;
      }

      p {
        margin-left: 24px;
        color: rgba(0, 0, 0, 0.60);
        font-size: 14px;
        font-weight: bold;

        span {
          font-size: 18px;
          color: rgba(0, 0, 0, 1);
        }
      }

      >span {
        position: absolute;
        right: 0;
        color: rgba(0, 0, 0, 0.60);
        font-size: 14px;
        font-weight: bold;
      }
    }

    // 普通列表
    .listBox {
      display: flex;
      flex-direction: column;

      h1 {
        height: 34px;
        font-size: 24px;
        display: flex;
      }

      .allLabel {
        // overflow-y: auto;
        width: 1200px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        .labelBox {
          margin-bottom: 24px;
          padding: 16px;
          display: flex;
          height: 144px;
          width: 100%;
          border-radius: 16px;
          background-color: #fff;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          border: 1px solid transparent;
          cursor: pointer;

          .labelLeft {
            margin-right: 16px;
            width: 112px;
            height: 112px;
            border-radius: 16px;

            img {
              width: 112px;
              height: 112px;
              border-radius: 16px;
            }
          }

          .labelMiddle {
            margin-right: 24px;
            width: calc(100% - 372px);

            .h4Class {
              margin: 2px 0 18px;
              display: flex;
              justify-content: space-between;

              p {
                font-size: 18px;
                color: rgba(0, 0, 0, 0.8);
                font-weight: bold;
                line-height: 18px;

                span {
                  margin-left: 60px;
                  display: inline-block;
                  // width: 140px;
                  font-size: 14px;
                  color: rgba(0, 0, 0, 0.60);

                  i {
                    color: rgba(24, 144, 255, 1);
                  }
                }
              }
            }

            >p {
              margin-bottom: 12px;
              color: rgba(0, 0, 0, 0.60);
              font-size: 14px;
              line-height: 14px;
              font-weight: bold;
            }

            .mainContent {
              display: flex;

              .mainBox {
                margin-bottom: 24px;
                display: flex;
                width: 20%;

                .mainImg {
                  margin-right: 9px;
                  width: 40px;
                  height: 40px;
                  background-color: rgba(24, 144, 255, 0.10);
                  border-radius: 8px;

                  img {
                    margin: 8px;
                    width: 24px;
                    height: 24px;
                  }
                }

                >div {
                  p {
                    color: rgba(0, 0, 0, 0.40);
                    font-size: 12px;
                    font-weight: bold;
                  }

                  span {
                    margin-top: 2px;
                    display: block;
                    font-size: 14px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.80);
                  }
                }
              }

              .mainBox:nth-child(1) {
                width: 25%;
              }

              .mainBox:nth-child(3) {
                >div span {
                  width: 100px;
                }
              }

              .mainBox:nth-child(4) {
                width: 35%;
              }
            }
          }

          .labelRight {
            position: relative;
            display: flex;
            align-items: center;
            padding-left: 24px;
            width: 244px;
            border-left: 1px solid rgba(0, 0, 0, 0.10);
            box-sizing: border-box;
            -moz-box-sizing: border-box;

            .rightShow {
              padding: 22px 0;
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              align-items: center;
              width: 116px;
              height: 96px;
              background-size: 100% 100%;
              background-image: url(../../assets/img/index/index5.png);
              box-sizing: border-box;
              -moz-box-sizing: border-box;

              p {
                width: 100%;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.40);
                text-align: center;
              }

              span {
                font-size: 14px;
                font-weight: bold;
                color: rgba(24, 144, 255, 1);
                text-align: center;
              }
            }

            .el-button {
              margin-left: 24px;
              padding: 11px 15px;
              width: 104px;
              height: 40px;
              font-size: 14px;
            }

            >p {
              position: absolute;
              top: 80px;
              right: 10px;
              color: rgba(0, 0, 0, 0.40);
              font-size: 12px;
              font-weight: bold;

              span {
                color: rgba(24, 144, 255, 1);
              }
            }
          }
        }

        .labelBox:hover {
          box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.1);
          border: 1px solid #1890FF;
        }
      }

      .allLabel2 {
        // overflow-y: auto;
        width: 1200px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        .labelBox {
          margin-bottom: 24px;
          padding: 24px;
          display: flex;
          height: 144px;
          width: 100%;
          border-radius: 16px;
          background-color: #fff;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          border: 1px solid transparent;
          cursor: pointer;

          .labelLeft {
            width: calc(100% - 244px);

            .h4Class {
              margin: 0 0 21px;
              line-height: 24px;
              font-size: 18px;
              color: rgba(0, 0, 0, 0.8);
              font-weight: bold;

              >p {
                margin: -1px 0 0 8px;
                display: inline-block;
                vertical-align: top;

                i {
                  display: inline-block;
                  width: 36px;
                  height: 24px;
                  background-color: rgba(24, 144, 255, 1);
                  border-radius: 24px;
                  font-size: 12px;
                  text-align: center;
                  line-height: 24px;
                  color: #fff;
                }

                i:nth-child(2) {
                  background: #000;
                }
              }
            }

            .mainContent {
              display: flex;

              .mainBox {
                margin-bottom: 24px;
                display: flex;
                width: 33%;

                .mainImg {
                  margin-right: 9px;
                  width: 40px;
                  height: 40px;
                  background-color: rgba(24, 144, 255, 0.10);
                  border-radius: 8px;

                  img {
                    margin: 8px;
                    width: 24px;
                    height: 24px;
                  }
                }

                >div {
                  p {
                    color: rgba(0, 0, 0, 0.40);
                    font-size: 12px;
                    font-weight: bold;
                  }

                  span {
                    margin-top: 2px;
                    display: block;
                    font-size: 14px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.80);
                  }
                }
              }
            }
          }

          .labelRight {
            position: relative;
            display: flex;
            align-items: center;
            padding-left: 24px;
            width: 244px;
            border-left: 1px solid rgba(0, 0, 0, 0.10);
            box-sizing: border-box;
            -moz-box-sizing: border-box;

            .rightShow {
              padding: 22px 0;
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              align-items: center;
              width: 116px;
              height: 96px;
              background-size: 100% 100%;
              background-image: url(../../assets/img/index/index5.png);
              box-sizing: border-box;
              -moz-box-sizing: border-box;

              p {
                width: 100%;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.40);
                text-align: center;
              }

              span {
                font-size: 14px;
                font-weight: bold;
                color: rgba(24, 144, 255, 1);
                text-align: center;
              }
            }

            .el-button {
              margin-left: 24px;
              padding: 11px 15px;
              width: 104px;
              height: 40px;
              font-size: 14px;
            }

            >p {
              position: absolute;
              top: 72px;
              right: 10px;
              color: rgba(0, 0, 0, 0.40);
              font-size: 12px;
              font-weight: bold;

              span {
                color: rgba(24, 144, 255, 1);
              }
            }
          }
        }

        .labelBox:hover {
          box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.1);
          border: 1px solid #1890FF;
        }
      }

      .allLabel3 {
        // overflow-y: auto;
        width: 1200px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        .labelBox {
          margin-bottom: 24px;
          padding: 16px;
          display: flex;
          height: 144px;
          width: 100%;
          border-radius: 16px;
          background-color: #fff;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          border: 1px solid transparent;
          cursor: pointer;

          .labelLeft {
            margin-right: 16px;
            width: 112px;
            height: 112px;
            border-radius: 16px;

            img {
              width: 112px;
              height: 112px;
              border-radius: 16px;
            }
          }

          .labelMiddle {
            margin-right: 24px;
            width: calc(100% - 372px);

            .h4Class {
              margin: 2px 0 18px;

              p:nth-child(1) {
                font-size: 18px;
                color: rgba(0, 0, 0, 0.8);
                font-weight: bold;
                line-height: 18px;

                >span {
                  float: right;
                  display: inline-block;
                  font-size: 14px;
                  color: rgba(0, 0, 0, 0.60);

                  i {
                    color: rgba(24, 144, 255, 1);
                    font-size: 16px;
                  }
                }
              }

              p:nth-child(2) {
                margin-top: 10px;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.8);
                font-weight: bold;
                line-height: 12px;

                >span {
                  display: inline-block;
                  font-size: 14px;
                  color: rgba(0, 0, 0, 0.40);

                  i {
                    color: rgba(0, 0, 0, 0.80);
                    line-height: 15px;
                  }
                }

                span:nth-child(2) {
                  margin-left: 20px;
                }

                span:nth-child(3) {
                  float: right;
                }

                span:nth-child(4) {
                  margin-right: 20px;
                  float: right;
                }
              }
            }

            .mainContent {
              margin-top: -10px;
              padding: 10px 12px;
              height: 60px;
              background-color: rgba(245, 246, 247, 1);
              border-radius: 8px;
              box-sizing: border-box;

              p {
                color: rgba(0, 0, 0, 0.60);
                font-size: 14px;
                font-weight: bold;
              }

              p:nth-child(2) {
                font-size: 12px;

                span {
                  margin-right: 40px;
                  color: rgba(0, 0, 0, 0.40);

                  i {
                    margin-left: 5px;
                    color: rgba(0, 0, 0, 0.80);
                  }
                }
              }
            }
          }

          .labelRight {
            position: relative;
            display: flex;
            align-items: center;
            padding-left: 24px;
            width: 244px;
            border-left: 1px solid rgba(0, 0, 0, 0.10);
            box-sizing: border-box;
            -moz-box-sizing: border-box;

            .rightShow {
              padding: 22px 0;
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
              align-items: center;
              width: 116px;
              height: 96px;
              background-size: 100% 100%;
              background-image: url(../../assets/img/index/index5.png);
              box-sizing: border-box;
              -moz-box-sizing: border-box;

              p {
                width: 100%;
                font-size: 12px;
                font-weight: bold;
                color: rgba(0, 0, 0, 0.40);
                text-align: center;
              }

              span {
                font-size: 14px;
                font-weight: bold;
                color: rgba(24, 144, 255, 1);
                text-align: center;
              }
            }

            .el-button {
              margin-left: 24px;
              padding: 11px 15px;
              width: 104px;
              height: 40px;
              font-size: 14px;
            }

            >p {
              position: absolute;
              top: 80px;
              right: 10px;
              color: rgba(0, 0, 0, 0.40);
              font-size: 12px;
              font-weight: bold;

              span {
                color: rgba(24, 144, 255, 1);
              }
            }
          }
        }

        .labelBox:hover {
          box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.1);
          border: 1px solid #1890FF;
        }
      }

      .jumpBox {
        width: 1200px;
        height: 32px;
        background: #FFFFFF;
        border-radius: 16px 16px 16px 16px;
        border: 1px solid transparent;
        cursor: pointer;
        text-align: center;
        line-height: 32px;
        color: rgba(0, 0, 0, 0.40);
        font-size: 14px;
        font-weight: bold;
      }

      .jumpBox:hover {
        border: 1px solid rgba(24, 144, 255, 1);
        color: rgba(24, 144, 255, 1);
      }
    }
  }

  // 活跃企业
  .activeBox {
    h3 {
      margin: 33px 0 33px;
      width: 100%;
      text-align: center;
    }

    .logoBox {
      margin-bottom: 40px;
      padding: 18px 20px;
      width: 100%;
      background-color: #fff;
      border-radius: 16px;
      box-sizing: border-box;

      p {
        margin: 14px 0 12px;
        font-weight: bold;
        font-size: 16px;
      }

      p:nth-child(1) {
        margin-top: 0;
      }

      >div {
        img {
          margin-right: 20px;
          width: 176px;
          height: 56x;
        }

        img:nth-child(6) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
